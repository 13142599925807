@import (reference) '../../css/_constants.less';

:local(.wrapperClass)_scroller {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;

  &__scroller-wrapper {
    height: 100%;
  }

  &__scroller-content {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: content-box;

    width: 100%;
    height: 100%;
    padding-right: 20px;
  }

  &__scrollbar {
    position: absolute;
    z-index: @z-static-top;
    width: 12px;
    background: transparent;
    top: 0;
    bottom: 0;
    right: 0;

    &__pill {
      width: 4px;
      position: absolute;
      top: 50px;
      right: 4px;
      min-height: 16px;
      background-color: @black-80;
      border-radius: 4px;
    }
  }
}