@import (reference) '../../css/_constants.less';

:local(.wrapperClass)_snooze-list {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;

  &__subheader {
    flex-grow: 0;
    .t-micricopy_13-16;
    color: @black-30;
    padding-left: 1rem;

    @media @mq-mobile {
      padding-left: 1.25rem;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    box-sizing: content-box;
    gap: 0.5rem;
    
    @media @mq-mobile {
      flex-direction: column;
      margin: 0 0.75rem 1.25rem 1.25rem;
      gap: 0;
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    width: (100% / 4);
    position: relative;

    &__subheader {
      .t-micricopy_13-16;
      padding: 0.5rem 0 0.5rem 1rem;

      &__label { color: @black-30; }
      &__meta { color: @black-60; }
    }
  }
  @media @mq-mobile {
    &__group {
      width: 100%;

      &__subheader {
        padding-left: 0.75rem;
      }
    }
  }

  &__group__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    gap: 1px;
  }
  @media @mq-mobile {
    &__group__wrapper {
      flex-direction: row;
    }
  }

  &__item {
    box-sizing: border-box;
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 1px @black-80;
    padding: 0.25rem;

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  @media @mq-mobile {
    &__item {
      flex-direction: row;
      width: auto;
      flex-grow: 1;

      &:last-child {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 0;
      }
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-radius: 2px;
    .t-micricopy_13-16;
    width: 100%;
    height: 100%;
    justify-content: center;
    text-align: center;
    gap: 0.125rem;

    &__time {
      color: @black-50 !important;
      .t-mobile-supermicro_11-12;
    }

    &:hover {
      background: linear-gradient(60deg, rgba(@pure-blue-80, 0.5), rgba(@purple-70, 0.5));
    }
  }

  /* 
    Auxillary styles
  */

  &__group-bullet {
    height: 0.5rem;
    width: 0.5rem;
    box-sizing: border-box;
    background: @black-100;
    border: 0.125rem solid @pure-blue-50;
    position: absolute;
    left: -0.25rem;
    top: 1.75rem;
    border-radius: 0.5rem;
    z-index: 2;
  }
  @media @mq-mobile {
    &__group-bullet {
      top: 0.75rem;
    }
  }

  &__group-line {
    height: 0.125rem;
    position: absolute;
    top: 2rem;
    transform: translateY(-1px);
    left: 0;
    right: -0.5rem;
    background: @pure-blue-50;
    z-index: 1;
  }
  &__group:last-child &__group-line { right: -1px }
  @media @mq-mobile {
    &__group-line {
      width: 0.125rem;
      height: auto;
      top: 0.8rem;
      bottom: -2rem;
      left: -1px;
    }
    &__group:last-child &__group-line { bottom: -1px }
  }


  &__group_0 &__group-bullet { border-color: @purple-70;}
  &__group_0 &__group-line { background: linear-gradient(to right, @purple-70, @purple-40); }
  @media @mq-mobile { &__group_0 &__group-line { background: linear-gradient(to bottom, @purple-70, @purple-40); } }

  &__group_1 &__group-bullet { border-color: @purple-40;}
  &__group_1 &__group-line { background: linear-gradient(to right, @purple-40, @pure-blue-60); }
  @media @mq-mobile {  &__group_1 &__group-line { background: linear-gradient(to bottom, @purple-40, @pure-blue-60); } }

  &__group_2 &__group-bullet { border-color: @pure-blue-60;}
  &__group_2 &__group-line { background: linear-gradient(to right, @pure-blue-60, @pure-blue-50); }
  @media @mq-mobile {  &__group_2 &__group-line { background: linear-gradient(to bottom, @pure-blue-60, @pure-blue-50); } }
}