@import (reference) '../../css/_constants.less';

@swap-duration: 1000ms;

:export {
  swapDuration: @swap-duration
}

@wrap-padding: 0.75rem;

:local(.wrapperClass)_shortlink-item {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: @black-100;
  border-radius: 4px;
  box-shadow: @shadow-light-20;

  &__shortlink-meta {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0.25rem 0.25rem @wrap-padding;
  }

  &__display-shortlink {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 0.125rem;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
  }

  &__shortlink {
    color: @black-20 !important;
    .t-micricopy_13-16;
    height: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__action-hint,
  &__separator {
    display: flex;
    opacity: 1;
    .t-micricopy_13-16;
    height: 1rem;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    transition: opacity 0.1s ease-in-out;
  }

  &__action-hint__animated-inner {
    position: relative;
    display: flex;
    animation-timing-function: ease-in;
    animation-duration: @swap-duration;
    animation-timing-function: ease-in-out;
  }
  &__shortlink_anim-active &__action-hint__animated-inner {
    animation-name: test-swap;
  }

  &__display-full-link {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: @wrap-padding;
    margin-bottom: 0;
    padding-bottom: @wrap-padding;
    border-bottom: 1px solid @black-90;
    flex-grow: 1;
    align-items: flex-start;

    &_no-description {
      gap: 0.25rem;
    }

    &__subheader {
      display: flex;
      flex-direction: row;
      gap: 0.375rem;
      color: @black-60;
      overflow: hidden;
      flex-grow: 1;
      .t-micricopy_13-16;
      text-overflow: ellipsis;
      height: 1rem;
      width: 100%;
      align-items: center;

      &__span {
        font-family: @font-face-system;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 500;
      }
    }

    &__main {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0.25rem;
    }

    &__title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &__title {
      .t-text_16-20;
      height: 1.25rem;
    }
    &__description {
      padding-top: 0.125rem;
      max-height: 2.25rem;
      .t-micricopy_relaxed_body_13-18;
      color: @black-30 !important;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-wrap: break-word;
    }

    &__favicon {
      height: 1rem;
      width: 1rem;
      border-radius: 2px;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  &_loading {
    width: 100%;
  }
  &_loading &__display-full-link { border: none;}
  &_loading &__display-full-link__title,
  &_loading &__display-full-link__subheader__span {
    display: block;
    background: linear-gradient(to right, 
      rgba(@black-95, 1) 0%,
      rgba(@black-95, 1) 30%,
      rgba(@black-95, 0) 48%,
      rgba(@black-95, 0) 52%,
      rgba(@black-95, 1) 70%,
      rgba(@black-95, 1) 100%
    );
    background-size: 200% 100%;
    animation: loading 2s linear infinite;
    border-radius: 4px;
  }
  &_loading &__display-full-link__title { width: 60% }
  &_loading &__display-full-link__subheader__span { width: 70% }

}

@keyframes test-swap {
  0% {
    top: 0rem;
  }
  12% {
    top: -1rem;
  }
  88% {
    top: -1rem;
  }
  100% {
    top: 0;
  }
}
