@import (reference) '../../css/_constants.less';

@button-border-radius: 4px;

.button-shadow-backdrop {
	content: ' ';
	position: absolute;
	border-radius: @button-border-radius;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: linear-gradient(180deg, @button-foreshadow-gradient-start 0%, @button-foreshadow-gradient-end 100%);
	z-index: -1;
}

.button-shadow-backdrop-primary {
	content: ' ';
	position: absolute;
	border-radius: @button-border-radius;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: linear-gradient(180deg, @button-foreshadow-gradient-start 0%, @button-foreshadow-gradient-end-intensive 100%);
	z-index: -1;
}

.primary-loading-gradient {
	background: linear-gradient(to right, 
			@pure-blue-95 0%,
			@pure-blue-95 15%,
			@pure-blue-70 46%,
			@pure-blue-70 54%,
			@pure-blue-95 85%,
			@pure-blue-95 100%
		);
}
.secondary-loading-gradient {
	background: linear-gradient(to right, 
			@pure-blue-60 0%,
			@pure-blue-60 15%,
			@pure-blue-90 46%,
			@pure-blue-90 54%,
			@pure-blue-60 85%,
			@pure-blue-60 100%
		);
}
.button-loading-mixin {
	background-size: 200% 100%;
	-webkit-background-clip: text;
	background-clip: text;
	animation: loading 2s linear infinite;
	color: transparent;
}


:local(.wrapperClass)_button {
	display: flex;
	align-items: center;
	border-radius: @button-border-radius;
	align-items: center;
	cursor: pointer;
	box-sizing: border-box;
	position: relative;
	transition: transform 0.1s ease-out;
	transform: scale(1);
	user-select: none;
  position: relative;
  text-decoration: none;
  flex-grow: 0;

	&:focus {
		box-shadow: 0 0 0 0.125rem @pure-blue-90;
	}
	&_disabled {
		cursor: not-allowed;
    &:active {
      transform: none !important;
    }
	}

	&_large {
		.t-text_16-20;
		padding: 0.5rem 0.75rem;
		gap: 0.5rem;
		height: 2.5rem;
		&:active {
			transform: scale(0.96);
		}
	}
	&_large&_icon-only {
		padding: 0.5rem;
		&:active {
			transform: scale(0.90);
		}
	}

	/*  
		Sizes
	 */
	&_small {
		.t-micricopy_13-16;
		padding: 0.375rem 0.75rem;
		gap: 0.25rem;
		height: 2rem;
		&:active {
			transform: scale(0.94);
		}
	}
	&_small&_icon-only {
		padding: 0.375rem;
		&:active {
			transform: scale(0.84);
		}
	}

	/* 
		Types
	 */
	&_primary {
		background-color: @pure-blue-40;
		color: @pure-blue-95;
		&:after {
			.button-shadow-backdrop-primary;
		}
		&:hover {
			color: @black-100;
		}
	}
	&_primary&_disabled {
		background-color: @pure-blue-70;
		color: @pure-blue-95;
	}
	&_primary&_loading &__label {
		display: inline-block;
		.primary-loading-gradient;
		.button-loading-mixin;
	}

	&_ghost {
		background-color: transparent;
		&:hover {
			background-color: @pure-blue-95;
		}
	}
	&_ghost&_icon_only:hover {
		background-color: transparent;
	}
	&_ghost&_disabled {
		color: @black-50;
	}

	&_secondary,
	&_ghost {
		color: @pure-blue-50;
		&:hover {
			color: @pure-blue-40;
		}
	}

	&_secondary {
		background-color: @pure-blue-90;
		&:after {
			.button-shadow-backdrop;
		}

	}
	&_secondary&_disabled {
		background-color: @pure-blue-90;
		color: @pure-blue-60;
	}
	&_secondary&_loading &__label {
		display: inline-block;
		.secondary-loading-gradient;
		.button-loading-mixin;
	}
  

  &_full-width {
    flex-grow: 1;
    justify-content: center;
  }
}