@import (reference) '../../css/_constants.less';

:local(.wrapperClass)_footer {
  box-sizing: border-box;
  font-family: @font-face-system;
  font-size: 0.75rem;
  line-height: 1rem;
  color: @black-50;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.375rem 0;
  border-top: 1px solid @black-95;
  margin: 0 1rem;

  @media @mq-mobile {
    flex-direction: column;
    gap: 0.25rem;
    padding: 0.375rem 0;
    margin: 0 1.25rem;
    align-items: center;
  }
  
  &__wrapper {
    .main-page-width-mixin;
    margin: 0 auto; 
  }
  @media @mq-mobile {
    &__wrapper {
      width: 100%;
    }
  }

  &__item {
    height: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
  }

  &__flag-icon {
    box-shadow: @shadow-light-20, @thin-shadow-stroke;
    border-radius: 2px;
  }
}