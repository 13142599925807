@import (reference) '../../css/_constants.less';

:local(.wrapperClass)_hero-input {
  color: @text-color;
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 2px solid @pure-blue-50;
  border-radius: 0.5rem;
  position: relative;
  box-sizing: border-box;
  gap: 0.5rem;
  padding-right: 0.5rem;
  background-color: @pure-blue-95;

  @media @mq-mobile {
    & {
      margin-left: -2px;
      margin-right: -2px;
    }
    .__mobile-convenience-state & {
      margin-left: 0;
      margin-right: 0;
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      flex-wrap: wrap;
      padding: 0.75rem 0.75rem 0.75rem 0;
      gap: 0.25rem;
    }
    .__mobile-convenience-state &_empty&_can-shortcut-paste {
      gap: 0;
    }
  }

  /* 
    mods
   */
  &_focus {
    box-shadow: 0 0 0 0.125rem @pure-blue-90;
  }

  &_empty &__clear { display: none; }
  .__mobile-convenience-state &_empty &__clear { display: flex; }
  &_can-shortcut-paste &__paste { display: none; }
  &_not-empty &__paste { display: none; }
  &_empty &__create { display: none; }
  &_empty &__snooze { display: none; }

  &__input-elem {
    .t-secondary-header_18-24;
    background-color: transparent;
    border-radius: 0.5rem;
    border: none;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 4rem;
    outline: none;
    display: block;
    padding: 1rem;
    padding-right: 0;
    z-index: 1;
  }
  @media @mq-mobile {
    &__input-elem {
      .t-text_16-20;
      line-height: 1.5rem;
      padding: 1rem 0 1rem 0.75rem;
    }
    .__mobile-convenience-state &__input-elem {
      padding: 0.5rem 0 0.5rem 1.25rem;
    }
    &__cta-actions__mobile-tip { 
      display: block;
    }
  }

  &__actions {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  @media @mq-mobile {
    .__mobile-convenience-state &__cta-actions {
      justify-content: flex-end;
      width: 100%;
    }
  }

  &__mobile-tip { display: none;}

  &__placeholder {
    .t-secondary-header_18-24;
    line-height: 1.5rem;
    position: absolute;
    z-index: 0;
    color: @black-50;
    top: 1rem;
    left: 1rem;

    &_show { visibility: visible;}
    &_hide { visibility: hidden;}
  }
  @media @mq-mobile {
    &__placeholder {
      .t-text_16-20;
      line-height: 1.5rem;
      left: 0.75rem;
    }
    .__mobile-convenience-state &__placeholder {
      top: 1.25rem;
      left: 1.25rem;
    }
  }
}