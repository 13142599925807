@import (reference) '../../css/_constants.less';

.faded-loading-gradient {
	background-image: linear-gradient(to right, 
			@black-60 0%,
			@black-60 15%,
			transparent 46%,
			transparent 54%,
			@black-60 85%,
			@black-60 100%
		);
}
.faded-text-loading-mixin {
	background-size: 200% 100%;
	-webkit-background-clip: text;
	background-clip: text;
	animation: loading 2s linear infinite;
	color: transparent;
}

@search-height: 7.75rem;

:local(.wrapperClass)_shortlink-list-app {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  -webkit-overflow-scrolling: none;
  height: 100%;
  background-color: @pure-blue-95;
  border-radius: 4px;
  padding-top: @search-height;

  @media @mq-mobile {
    border-radius: 0;
  }

  &__search {
    background-color: @black-100;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: @shadow-light-20;
    padding: 1rem 1.25rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: @z-static-middle;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__search__controls {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  &__scroller {
    height: 100%;
  }

  &__list {
    padding: 1rem 1.25rem;
    gap: 0.75rem;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__subheader {
    .t-micricopy_13-16;
    color: @black-50;
    padding: 0.5rem 0.75rem 0 0.75rem;
  }

  &__list-footer_nothing,
  &__list-footer_empty {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 4rem;
    justify-content: center;
    align-items: center;
  }
  &__list-footer_nothing {
    .t-secondary-header_18-24;
    color: @black-60;
  }

  /* 
    Snackbar and Url Edit modal
   */

  &__snackbar-container {
    position: absolute;
    bottom: 2.75rem;
    left: 1.25rem;
    right: 1.25rem;
    z-index: @z-popup-top;

    @media @mq-mobile {
      position: fixed;
    }
  }
  &__shortlink-list-error,
  &__shortlink-list-success {
    width: 100%;
    min-width: auto;
  }
}