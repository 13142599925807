@import (reference) '../../css/_constants.less';

:local(.wrapperClass) {

	svg {
		width: 100%;
		height: 100%;
	}
}

.icon-svg {
	&_size-large {
		width: 24px;
		height: 24px;
	}
	&_size-small {
		width: 20px;
		height: 20px;
	}
}