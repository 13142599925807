@import (reference) './_constants.less';

.__loading-skeleton-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  .main-page-width-mixin;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  gap: 2rem;
  align-items: center;
  z-index: 1;
  box-sizing: border-box;

  @media @mq-mobile {
    width: 100%;
    padding: 0 1.25rem;
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, 
      rgba(@black-100, 0) 0%,
      rgba(@black-100, 0) 30%,
      rgba(@black-100, 0.84) 48%,
      rgba(@black-100, 0.84) 52%,
      rgba(@black-100, 0) 70%,
      rgba(@black-100, 0) 100%
    );
    background-size: 200% 100%;
    animation: loading 2s linear infinite;
  }

  .__faded-logo {
    color: @black-80;

    .__faded-logo__svg {
      width: 92px;
      height: 92px;
    }
  }

  .__faded-input {
    box-sizing: border-box;
    border: 2px solid @black-80;
    border-radius: 0.5rem;
    flex-grow: 1;
    height: 3.75rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:after {
      display: flex;
      content: ' ';
      height: 1.25rem;
      border-radius: 2px;
      background-color: @black-90;
      width: 18%;
      margin-left: 1rem;
    }

    @media @mq-mobile {
      height: 3.5rem;

      &:after {
        width: 44%;
      }
    }
  }
}