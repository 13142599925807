@import (reference) '../css/_constants.less';

#app {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.mixin-fullscreen-page {
  box-sizing: border-box;
  padding-top: @mq-header-height;
  height: 100%;

  @media @mq-mobile {
    padding-top: @mq-mobile-header-height;
    height: 100%;
  }

  &&_no-header {
    padding-top: 0;
  }
}

.mixin-vertical-centered-body {
  height: 100%;
  max-height: 600px;
}

.mixin-centered-content {
  display: flex;
  flex-grow: 1;
  padding: 2rem 0;

  @media @mq-mobile {
    padding: 0;
  }

  @media @mq-not-mobile {
    justify-content: center;
    align-items: center;
  }
}

.mixin-narrow-body {
  box-sizing: border-box;
  width: 400px;
  border-radius: 0.5rem;
  border: 1px solid @black-90;
  min-height: 600px;
  position: relative;
  display: flex;

  @media @mq-mobile {
    width: 100%;
    border-radius: 0;
  }

  .narrow-body__back-button {
    .common-back-button;
    position: absolute;
    top: 0;
    left: -3.875rem;
  }

  @media @mq-mobile {
    width: 100%;
    border-radius: 0;
    border: none;
    min-height: auto;
  }
}

:local(.homeClass)_home {
  .mixin-fullscreen-page;
}

:local(.loginClass)_login {
  .mixin-fullscreen-page;
  display: flex;
  flex-direction: column;

  &__layout {
    .mixin-centered-content;
  }

  &__body {
    .mixin-narrow-body;
    .mixin-vertical-centered-body;
    justify-content: center;
    align-items: center;
  }

  &__login_content {
    display: flex;
    flex-direction: column;
    padding: 0 1.25rem;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
    height: 100%;
  }

  &__video {
    width: 320px;
    flex-shrink: 1;
    aspect-ratio: 1200 / 360;
  }

  &__intro_text {
    .t-secondary-header_18-24;
    text-align: center;
    color: transparent;
    background-image: linear-gradient(60deg, @red-50 0%, @purple-40 100%);
    -webkit-background-clip: text;
    background-clip: text;
  }

  &__legal {
    .t-micricopy_13-16;
    font-family: @font-face-system;
    color: @black-50;
    text-align: center;
    margin-top: 2.75rem;
  }
}

:local(.appMainClass)_app-main {
  .mixin-fullscreen-page;
  height: 100vh;
  -webkit-overflow-scrolling: none;
  overflow: hidden;

  @media @mq-mobile {
    box-sizing: border-box;
  }

  &__layout {
    .mixin-centered-content;
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
  }

  &__body {
    .mixin-narrow-body;
    height: 100%;
    flex-direction: column;
    background-color: @blue-95;
    border-radius: 4px;
  }

  &__shortlink-list {
    display: flex;
    flex-direction: column;
  }
}

:local(.profileClass)_profile {
  .mixin-fullscreen-page;
  display: flex;
  flex-direction: column;

  &__layout {
    .mixin-centered-content;
  }

  &__body {
    .mixin-narrow-body;
    .mixin-vertical-centered-body;
    justify-content: center;
    align-items: center;
  }

  &__profile-content {
    padding: 2rem 1.25rem;
    width: 100%;
    box-sizing: border-box;
  }
}

:local(.legalClass)_legal {
  .mixin-fullscreen-page;
  display: flex;
  flex-direction: column;

  &__layout {
    .mixin-centered-content;
  }

  &__body {
    .mixin-narrow-body;
    .mixin-vertical-centered-body;
    justify-content: center;
    align-items: center;
  }

  &__legal-content {
    .t-text_16-20;
    font-family: @font-face-system;
    padding: 2rem 1.25rem;
    width: 100%;
    box-sizing: border-box;
  }
}