@import (reference) '../../css/_constants.less';

:local(.widgetWrapper)_history-widget {
  box-sizing: border-box;
  display: flex;
  .t-micricopy_13-16;
  flex-direction: column;
  gap: 0.25rem;
  margin: 1.25rem 0;

  @media @mq-mobile {
    & {
      box-sizing: border-box;
      border-radius: 0.5rem;
      box-shadow: 0 0 0 2px @black-90;
      padding-top: 0.75rem;
      gap: 0;
    }
  }

  &__link-block {
    display: flex;
    flex-direction: column;
    width: 33%;
    overflow: hidden;
    box-sizing: border-box;
    box-shadow: -1px 0 0 0 @black-90;
    gap: 0;

    &:first-child { box-shadow: none; }
    @media @mq-mobile {
      &:first-child { box-shadow: none !important;}
    }

    &:hover {
      box-shadow: @shadow-light-10, @thin-shadow-stroke;
      border-radius: 2px;
    }

    &:hover + & {
      box-shadow: none;
    }
  }
  @media @mq-mobile {
    &__link-block {
      width: auto;
      box-shadow: 0 -1px 0 0 @black-90;

      &:hover, &:hover + & { box-shadow: 0 -1px 0 0 @black-90; }
    }
  }

  &__link-list {
    display: flex;
    flex-direction: row;
  }
  @media @mq-mobile {
    &__link-list {
      flex-direction: column;
      margin: 0 0.75rem;
    }
  }


  &__full-link {
    padding: 0.125rem 1rem 0.75rem 1rem;
    &__label {
      .t-micricopy_13-16;
      height: 1rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  @media @mq-mobile {
    &__full-link {
      padding: 0.25rem 0 1rem 0;
    }
  }

  &__shortlink {
    padding: 0.75rem 1rem 0.125rem 1rem;
    align-items: baseline;
    &__label {
      .t-text_16-20;
      height: 1.25rem;
      color: @black-30;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  @media @mq-mobile {
    &__shortlink {
      padding: 1rem 0 0.25rem 0;
    }
  }

  &__header {
    .t-micricopy_13-16;
    color: @black-50;
    padding: 0 1rem;
  }
  @media @mq-mobile {
    &__header {
      padding: 0 0.75rem;
    }
  }

  &__loading &__shortlink__label,
  &__loading &__full-link__label {
    display: block;
    background: linear-gradient(to right, 
      rgba(@black-95, 1) 0%,
      rgba(@black-95, 1) 30%,
      rgba(@black-95, 0) 48%,
      rgba(@black-95, 0) 52%,
      rgba(@black-95, 1) 70%,
      rgba(@black-95, 1) 100%
    );
    background-size: 200% 100%;
    animation: loading 2s linear infinite;
    border-radius: 4px;
  }
  &__loading &__shortlink__label {
    width: 40%;
  }
  &__loading &__full-link__label {
    width: 70%;
  }

  &__all-links {
    margin-bottom: 0.75rem;
  }
}