@import (inline) 'normalize.css'; 
@import './_constants.less';
@import './globals.webapp.less';
@import './globals.extension.less';
@import './loading-skeleton.less';

.document {
  font-size: 16px;
}

@font-face {
  font-family: 'Mori';
  src: 	url('/src/assets/fonts/PPMori-SemiBold.eot');
  src: 	url('/src/assets/fonts/PPMori-SemiBold.woff') format('woff'),
        url('/src/assets/fonts/PPMori-SemiBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: @font-face;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: 'liga','kern';
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  font-size: 1rem;
  color: @text-color;
  height: 100vh;
}

