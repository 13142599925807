@import (reference) '../../css/_constants.less';

:local(.wrapperClass)_url-edit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(@black-30, 0.3);
  z-index: @z-popup-middle;

  &__url-form {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 1.25rem;
    background-color: @black-100;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: @shadow-light-20;

    &__controls {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
  }
}