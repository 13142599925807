@import (reference) '../../css/_constants.less';

:local(.wrapperClass)_app-header {
  position: fixed;
  padding-top: 1.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  background-color: rgba(@black-100, 0.98);
  position: fixed;
  top: 0;
  z-index: @z-static-top;
  left: 0;
  right: 0;

  @media @mq-mobile {
    padding: 1rem 1.25rem;
    padding-bottom: 0;
  }

  &__middle {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow: hidden;
    margin: 0 1rem;
    justify-content: center;
  }
  &__title_wrapper {
    display: none;
    flex-direction: column;
    align-items: center;

    @media @mq-mobile {
      display: flex;
    }
  }

  &__account-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
    .t-secondary-header_18-24;

    &__avatar {
      .t-secondary-header_18-24;
      font-size: 1.375rem;
      box-shadow: @shadow-light-20, @thin-shadow-stroke;
      height: 2.875rem;
      width: 2.875rem;

      .icon-svg { width: 2rem; height: 2rem;}
    }

    @media @mq-mobile {
      &__text {display: none;}
    }
  }
  &__account-link &__account-link__avatar,
  &__dropdown-header__avatar {
    background-color: @pure-blue-90;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
  }
  &_logged-user &__account-link__avatar,
  &__dropdown-header__avatar {
    background-color: @pure-blue-50;
    color: @black-100;
  }

  &__logo {display: block; cursor: pointer;}
  @media @mq-mobile {
    &_has-back-button &__logo_wrapper { display: none; }
  }

  &__back_wrapper {
    .common-back-button;
    display: none;
    @media @mq-mobile {
      display: flex;
    }
  }

  &__title_wrapper &__header {
    .t-secondary-header_18-24;
    font-weight: 400;
    color: @black-10;
    padding: 0;
    margin: 0;
  }

  &__title_wrapper &__sub-header {
    .t-micricopy_13-16;
    color: @black-50;
    font-size: 0.625rem;
    line-height: 0.875rem;
    letter-spacing: 0.1em;
    color: @black-40;
    text-transform: uppercase;
  }

  /* 
    Dropdown
   */
  &__dropdown {
    width: 16rem;
  }
  &__dropdown-header {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    padding: 0.25rem 0;
    padding-left: 0.5rem;
    align-items: center;
  }
  &__dropdown-header__avatar {
    font-size: 1.25rem;
    width: 2.75rem;
    height: 2.75rem;
    margin-right: 0.125rem;
  }
  &__dropdown-header__name-block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &__name {
      .t-micricopy_13-16;
      color: @black-30;
    }
    &__email {
      .t-micricopy_13-16;
      color: @black-50;
    }
  }
}